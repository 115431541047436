<template>
  <div class="page_container">
    <div class="content_breadcrumb">
      <div class="content_nav">
        当前位置&nbsp;： 新闻资讯 &nbsp;&nbsp; >&nbsp;&nbsp;
        <span class="content_detail">详细内容</span>
      </div>
    </div>
    <div class="news_content container">
        <div class="left">
          <div class="tit">
            <h1>国产引擎铸“大国重器”——长安大学申报的国家重点研发计划项目获批复</h1>
            <div class="time">2020-11-25 </div>
            <div class="info">
              <p>近日，科技部公布了2020年度国家重点研发计划“重大自然灾害监测预警与防范”重点专项立项情况。由长安大学作为牵头单位组织申报的“大范围自然灾害交通网信息全息感知与智能控制及安全诱导技术装备研发”计划项目正式获得科技部批复立项。</p>
              <p class="p_pic">
                <span style="font-size: 14px;"><img src="../../assets/image/news/new_changan.jpg"></span>
              </p>
              <p>项目将基于“黑洞BIM+GIS三维图形引擎”（以下简称黑洞引擎）进行研发。以“黑洞引擎”对倾斜摄影模型、点云模型、GIS遥感影像等数据与高精度BIM数据集成能力，构建大范围自然灾害交通网数字孪生模型，耦合专家高级认知机制与机器智能系统，开展交通网多灾种条件下超大场景应急救援动态推演和灾后全过程仿真重现。通过“黑洞引擎”多维空间块的瓦片技术实现超大模型和超大场景的无延时各层级动态高逼真渲染，对“天-空-地-基-人”五位一体的交通网全息感知数据可视化实时表达。</p>
              <p  class="p_pic">
                <span style="font-size: 14px;"><img src="../../assets/image/news/new_changan02.png"></span>
              </p>
              <p> 该项目面向大范围自然灾害交通网应急救援的国家重大战略需求，围绕应急救援生命线开展大范围多灾种场景下道路交通网“智能损毁侦测、全息灾情识别、北斗精准控导、物资联动调拨”关键技术研究，研发应急救援“预警、控制、诱导”先进装备和一体化协同调度指挥平台，架构应急救援技术装备体系，开展极端气象、洪涝、地质灾害条件下典型区域示范应用，推动交通自然灾害应急救援体系标准化，全面提升我国交通网灾害应急减灾救灾水平。</p>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="tit"><h3><font></font>热点内容</h3></div>
          <ul>
            <li v-for="item in hotList" :key="item.id">
              <p><router-link :to="item.link">{{item.name}}</router-link></p>
              <span>{{item.time}}</span>
            </li>
          </ul>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      hotList: [
        {id:1,name: '“黑洞”图形引擎助力中电建华东院打造智慧城市CIM平台',time: '2020-11-09',link:'/newsDetails_new_CIM'},
        {id:2,name: '秉匠科技又双叒叕获奖了！',time: '2020-10-28',link:'/newsDetails_new_BIM'},
        {id:3,name: '江苏东交智控科技集团股份有限公司与秉匠科技签订“黑洞”图形引擎采购合同',time: '2020-11-02',link:'/newsDetails_new_dongjiao'},
        {id:4,name: '“黑洞引擎”荣获“上海市第二届BIM技术应用创新大赛”特别创意优秀奖',time: '2020-8-10',link:'/newsDetails_new_hj'}
      ]
    }
  },
  mounted () {
      document.querySelector('.nav').style.backgroundColor = '#000'
  },
  destroyed () {
      document.querySelector('.nav').style.backgroundColor = 'transparent'
  }
}
</script>

<style lang="less" scoped>
.page_container{
  margin-top: 50px;
  .news_content{
    overflow: hidden;
    .left{
      width: 60%;
      height: 100%;
      .tit{
        h1{
          color: #222;
          font-size: 22px;
          font-weight: normal;
          line-height: 30px;
        }
        .time{
          margin: 10px;
          height: 27px;
          border-bottom: 1px #ddd solid;
          font-size: 14px;
          color: #999;
          padding-right: 30px;
        }
      }
      .info{
        padding-top: 32px;
        text-align: center;
        p{
          font-size: 16px;
          color: #222;
          line-height: 28px;
          text-align: left;
          text-indent:2em;
        }
        .p_pic{
          text-indent: 0;
        }
        img{
          margin: 16px 0;
        }
      }
    }
    .right{
      width: 35%;
      height: 100%;
      .tit{
        border-bottom: 1px #444 solid;
        padding-bottom: 14px;
        margin-top: 24px;
        font{
          width: 4px;
          height: 18px;
          background: #222;
          display: inline-block;
          margin-right: 16px;
          position: relative;
          top: 4px;
        }
      }
      ul{
        padding: 12px;
        li{
          line-height: 24px;
          font-size: 16px;
          color: #555;
          border-bottom: 1px #ddd solid;
          padding: 12px 0;
          p{
            a{
              color: #555;
            }
          }
          span{
            display: block;
            font-size: 14px;
            color: #777;
            padding-top: 4px;
          }
        }
        .router-link-active{
          border-bottom: none;
        }
      }
    }
  }
}
</style>
